import React, { useState, useRef, useEffect } from 'react';
import { Card, Segment, Icon, Button, Popup, Form } from 'semantic-ui-react';
import './MyCloset.css';
import { useNavigate } from 'react-router-dom';

const clothingItems = [
  {
    id: 1,
    name: 'Dress',
    category: 'Formal',
    description: 'A beautiful formal dress.',
    image: 'https://res.cloudinary.com/sheyou/image/upload/v1727901927/dress_0924208b71.png'
  },
  {
    id: 2,
    name: 'Skirt',
    category: 'Casual',
    description: 'A stylish casual skirt.',
    image: 'https://res.cloudinary.com/sheyou/image/upload/v1727901926/skirt_6aa14f9c3a.webp'
  },
  {
    id: 3,
    name: 'Crop Top',
    category: 'Casual',
    description: 'A trendy crop top.',
    image: 'https://res.cloudinary.com/sheyou/image/upload/v1727901926/crop_top_918bed2325.png'
  }
];

function MyCloset() {
  const [droppedItems, setDroppedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [placeholderWidth, setPlaceholderWidth] = useState(0);
  const [placeholderHeight, setPlaceholderHeight] = useState(0);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isMoving, setIsMoving] = useState(false);
  const [moveStartPos, setMoveStartPos] = useState({ x: 0, y: 0 });
  const [isResizing, setIsResizing] = useState(false);
  const [resizeStartPos, setResizeStartPos] = useState({ x: 0, y: 0 });
  const [resizeStartDimensions, setResizeStartDimensions] = useState({ width: 0, height: 0 });
  const navigate = useNavigate();
  const [isLookPopupOpen, setIsLookPopupOpen] = useState(false);
  const [lookName, setLookName] = useState('');
  const [isNoItemsPopupOpen, setIsNoItemsPopupOpen] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const folderIconRef = useRef(null);
  const placeholderRef = useRef(null);

  useEffect(() => {
    if (placeholderRef.current) {
      setPlaceholderWidth(placeholderRef.current.offsetWidth);
      setPlaceholderHeight(placeholderRef.current.offsetHeight);
    }
  }, []);

  const handleDragStart = (e, item) => {
    const rect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    setDragOffset({ x: offsetX, y: offsetY });
    e.dataTransfer.setData('text/plain', JSON.stringify(item));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const item = JSON.parse(e.dataTransfer.getData('text/plain'));
    const rect = placeholderRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left - dragOffset.x;
    const y = e.clientY - rect.top - dragOffset.y;
    
    const img = new Image();
    img.src = item.image;
    img.onload = () => {
      const aspectRatio = img.height / img.width;
      const newWidth = placeholderWidth / 1.5; // Adjust this value as needed
      const newHeight = newWidth * aspectRatio;
      const newItem = { ...item, x, y, width: newWidth, height: newHeight };
      setDroppedItems(prevItems => [...prevItems, newItem]);
      setSelectedItem(droppedItems.length); // Select the newly added item
    };
  };

  const handleItemClick = (index) => {
    setSelectedItem(index === selectedItem ? null : index);
  };

  const handleMoveStart = (e, index) => {
    e.stopPropagation();
    setIsMoving(true);
    setSelectedItem(index);
    const rect = placeholderRef.current.getBoundingClientRect();
    const item = droppedItems[index];
    setDragOffset({
      x: e.clientX - rect.left - item.x,
      y: e.clientY - rect.top - item.y
    });
  };

  const handleMove = (e) => {
    if (isMoving && selectedItem !== null) {
      const rect = placeholderRef.current.getBoundingClientRect();
      const newX = e.clientX - rect.left;
      const newY = e.clientY - rect.top;
      
      setDroppedItems(prevItems => 
        prevItems.map((item, index) => 
          index === selectedItem
            ? { 
                ...item, 
                x: newX - dragOffset.x,
                y: newY - dragOffset.y
              }
            : item
        )
      );
    }
  };

  const handleMoveEnd = () => {
    setIsMoving(false);
  };

  const handleResizeStart = (e, index) => {
    e.stopPropagation();
    setIsResizing(true);
    setSelectedItem(index);
    setResizeStartPos({ x: e.clientX, y: e.clientY });
    setResizeStartDimensions({
      width: droppedItems[index].width,
      height: droppedItems[index].height
    });
  };

  const handleResize = (e) => {
    if (isResizing && selectedItem !== null) {
      const deltaX = e.clientX - resizeStartPos.x;
      const deltaY = e.clientY - resizeStartPos.y;
      const aspectRatio = resizeStartDimensions.height / resizeStartDimensions.width;
      
      let newWidth = Math.max(50, resizeStartDimensions.width + deltaX);
      let newHeight = newWidth * aspectRatio;

      setDroppedItems(prevItems =>
        prevItems.map((item, index) =>
          index === selectedItem
            ? {
                ...item,
                width: newWidth,
                height: newHeight
              }
            : item
        )
      );
    }
  };

  const handleResizeEnd = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    if (isMoving) {
      window.addEventListener('mousemove', handleMove);
      window.addEventListener('mouseup', handleMoveEnd);
    }
    if (isResizing) {
      window.addEventListener('mousemove', handleResize);
      window.addEventListener('mouseup', handleResizeEnd);
    }
    return () => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleMoveEnd);
      window.removeEventListener('mousemove', handleResize);
      window.removeEventListener('mouseup', handleResizeEnd);
    };
  }, [isMoving, isResizing, selectedItem]);

  const handleCardClick = (item) => {
    const img = new Image();
    img.src = item.image;
    img.onload = () => {
      const aspectRatio = img.height / img.width;
      const newWidth = placeholderWidth/1.5;
      const newHeight = newWidth * aspectRatio;
      const x = (placeholderWidth - newWidth) / 2;
      const y = (placeholderHeight - newHeight) / 2;
      setDroppedItems([...droppedItems, { ...item, x, y, width: newWidth, height: newHeight }]);
    };
  };

  const handleCreateLook = () => {
    if (droppedItems.length === 0) {
      setIsNoItemsPopupOpen(true);
      setTimeout(() => setIsNoItemsPopupOpen(false), 3000); // Close after 3 seconds
    } else {
      setIsLookPopupOpen(true);
    }
  };

  const saveLook = () => {
    const look = droppedItems.map(item => ({
      id: item.id,
      image: item.image,
      x: item.x,
      y: item.y,
      width: item.width,
      height: item.height
    }));

    const existingLooks = JSON.parse(localStorage.getItem('savedLooks') || '[]');
    const newLook = {
      id: Date.now(),
      name: lookName,
      items: look
    };
    existingLooks.push(newLook);
    localStorage.setItem('savedLooks', JSON.stringify(existingLooks));
    setIsLookPopupOpen(false);
    setLookName('');
    setIsSuccessPopupOpen(true);
    setTimeout(() => setIsSuccessPopupOpen(false), 3000); // Close after 3 seconds
  };

  const handleDeleteSelection = () => {
    if (selectedItem !== null) {
      setDroppedItems(prevItems => prevItems.filter((_, index) => index !== selectedItem));
      setSelectedItem(null);
    }
  };

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleFolderClick = () => {
    navigate('/lookbook');
  };

  return (
    <div className="my-closet">
      <div className="sticky-wrapper">
        <div className="icon-container">
          <Icon name="home" size="large" color="teal" className="home-icon" onClick={handleHomeClick} />
          <Popup
            trigger={<Icon ref={folderIconRef} name="folder open" size="large" color="teal" className="folder-icon" onClick={handleFolderClick} />}
            content="Look Saved :)"
            position="top center"
            open={isSuccessPopupOpen}
            onClose={() => setIsSuccessPopupOpen(false)}
          />
        </div>
        <div 
          ref={placeholderRef}
          className="sticky-placeholder"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <img 
            src="https://res.cloudinary.com/sheyou/image/upload/v1727900291/placeholder_855d31aa79.svg" 
            className="placeholder-image"
            alt="Placeholder"
            draggable="false"
          />
          <div className="dropped-items-layer">
            {droppedItems.map((item, index) => (
              <div 
                key={`${item.id}-${index}`}
                className={`dragged-item-container ${selectedItem === index ? 'selected' : ''}`}
                style={{ 
                  left: `${item.x}px`, 
                  top: `${item.y}px`,
                  width: `${item.width}px`,
                  height: `${item.height}px`
                }}
                onClick={() => handleItemClick(index)}
              >
                <img 
                  src={item.image} 
                  className="dragged-image"
                  alt={item.name}
                  draggable="false"
                />
                {selectedItem === index && (
                  <>
                    <div 
                      className="move-icon-container"
                      onMouseDown={(e) => handleMoveStart(e, index)}
                    >
                      <Icon name="move" size="large" color="teal" />
                    </div>
                    <div 
                      className="resize-icon-container"
                      onMouseDown={(e) => handleResizeStart(e, index)}
                    >
                      <Icon name="expand" size="large" color="teal" />
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="button-container">
          <Popup
            open={isNoItemsPopupOpen}
            content="Please add some items to create a look."
            on="click"
            position="top right"
            trigger={
              <Popup
                on='click'
                pinned
                open={isLookPopupOpen}
                onClose={() => setIsLookPopupOpen(false)}
                trigger={<Button onClick={handleCreateLook} color="teal">Create a Look</Button>}
              >
                <Form>
                  <Form.Field>
                    <label>Look Name</label>
                    <input 
                      placeholder='Enter look name' 
                      value={lookName}
                      onChange={(e) => setLookName(e.target.value)}
                    />
                  </Form.Field>
                  <Button color='green' onClick={saveLook}>Confirm</Button>
                  <Button color='red' onClick={() => setIsLookPopupOpen(false)}>Cancel</Button>
                </Form>
              </Popup>
            }
          />
          <Button onClick={handleDeleteSelection} color="red" disabled={selectedItem === null}>Delete Selection</Button>
        </div>
      </div>
      <Segment className="scrollable-cards">
        <Card.Group centered>
          {clothingItems.map(item => (
            <Card 
              key={item.id}
              draggable
              onDragStart={(e) => handleDragStart(e, item)}
              onClick={() => handleCardClick(item)}
            >
              <img src={item.image} alt={item.name} className="card-image" />
              <Card.Content>
                <Card.Header>{item.name}</Card.Header>
                <Card.Meta>{item.category}</Card.Meta>
                <Card.Description>{item.description}</Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </Segment>
    </div>
  );
}

export default MyCloset;