import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import EnterPage from './components/home page/EnterPage';
import MyCloset from './components/my closet page/MyCloset';
import LookBook from './components/look book page/LookBook';
import 'semantic-ui-css/semantic.min.css'

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<EnterPage />} />
          <Route path="/my-closet" element={<MyCloset />} />
          <Route path="/lookbook" element={<LookBook />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
