import React, { useState, useEffect } from 'react';
import { Button, Segment } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import './EnterPage.css';

function EnterPage() {
  const navigate = useNavigate();
  const [weather, setWeather] = useState(null);

  useEffect(() => {
    // Fetch weather data for New York
    fetch('https://api.openweathermap.org/data/2.5/weather?q=New York&units=metric&appid=ad909fca09355978ceecd996d11f8eec')
      .then(response => response.json())
      .then(data => setWeather(data))
      .catch(error => console.error('Error fetching weather:', error));
  }, []);

  const handleEnterClick = () => {
    navigate('/my-closet');
  };

  return (
    <div className="enter-page">
      <h1 className="welcome-text">Welcome to SUSU Closet</h1>
      <Segment style={{ backgroundColor: '#f5f5dd', margin: '20px 0', padding: '20px' }}>
        <h2>New York</h2>
        {weather ? (
          <p>Today's weather: {weather.main.temp}°C, {weather.weather[0].description}</p>
        ) : (
          <p>Loading weather information...</p>
        )}
      </Segment>
      <Button 
        size="huge" 
        className="enter-button" 
        color="teal" 
        onClick={handleEnterClick}
      >
        Enter Virtual Closet
      </Button>
    </div>
  );
}

export default EnterPage;
